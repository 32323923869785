@import '../../../styles/colors';
@import '../../../styles/variables';

.compWrap {
  max-width: 992px;
  margin: 40px 20px;

  @media #{$desktop-large} {
    margin: 40px auto;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;

  & > svg {
    width: 78px;
    height: 64px;
    margin-bottom: 8px;
  }

  & > h1 {
    letter-spacing: 10px;
  }

  & > p {
    text-align: center;
  }
}

.festivals {
  margin: 60px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  width: 100%;
}
