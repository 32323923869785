@import '../../../styles/variables';
@import '../../../styles/colors';

.compWrap {
  max-width: 992px;
  margin: auto;
  padding: 40px 20px;

  @media #{$desktop-large} {
    padding: 100px 20px;
  }
}

.title {
  position: relative;
  text-align: center;
  margin-bottom: 80px;

  svg {
    width: 154px;
    height: 62px;
    position: absolute;
    top: 36px;
    left: 49.5%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.heading {
  position: relative;
  z-index: 2;
}

.paragraph {
  text-align: center;
  margin-bottom: 40px;
}

.hype {
  text-align: center;
  color: $red;
}

.hypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  @media #{$desktop-large} {
    margin-top: 20px;
    flex-direction: row;
  }

  & > div {
    margin-bottom: 40px;
    @media #{$desktop-large} {
      margin-bottom: 0;
    }
  }

  @media #{$desktop-large} {
    & > div:nth-child(2) {
      margin-top: 80px;
    }

    & > div:nth-child(3) {
      margin-top: 160px;
    }
  }
}
