@import '../../../styles/colors';
@import '../../../styles/variables';

$desktop-social-card-width: 200px;
$desktop-social-card-height: 200px;

$mobile-social-card-width: 150px;
$mobile-social-card-height: 150px;

.container {
  position: relative;
  background: white;
  border: none;
  width: $mobile-social-card-width;
  height: $mobile-social-card-height;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
  clip-path: polygon(0 0, 94% 0, 100% 10%, 100% 100%, 6% 100%, 0 90%);

  @media #{$desktop-large} {
    width: $desktop-social-card-width;
    height: $desktop-social-card-height;
  }
}

.border {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(#{$mobile-social-card-width} - 4px);
  height: calc(#{$mobile-social-card-height} - 4px);
  background: $grey;
  clip-path: polygon(0 0, 94% 0, 100% 10%, 100% 100%, 6% 100%, 0 90%);

  @media #{$desktop-large} {
    width: calc(#{$desktop-social-card-width} - 4px);
    height: calc(#{$desktop-social-card-height} - 4px);
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.newLabel {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background: $red;
  font-weight: bold;
}

.container:hover,
.new {
  background: $red;

  .border {
    top: 4px;
    left: 4px;
    width: calc(#{$mobile-social-card-width} - 8px);
    height: calc(#{$mobile-social-card-height} - 8px);

    @media #{$desktop-large} {
      width: calc(#{$desktop-social-card-width} - 8px);
      height: calc(#{$desktop-social-card-height} - 8px);
    }
  }
}
