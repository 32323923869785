@import '../../../styles/colors';
@import '../../../styles/variables';

.compWrap {
  position: relative;
}

.avatar {
  cursor: pointer;
  position: relative;
  border: 1px solid $white;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-color: $grey;

  width: 30px;
  height: 30px;

  svg {
    width: 15px;
    height: 15px;
  }

  @media #{$desktop-large} {
    width: 45px;
    height: 45px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  img {
    border-radius: 50%;
  }

  @media #{$desktop-large} {
    margin-right: unset;
  }
}

.small {
  width: 30px;
  height: 30px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.large {
  border: 2px solid $white;

  width: 80px;
  height: 80px;

  svg {
    width: 30px;
    height: 30px;
  }

  @media #{$desktop-large} {
    width: 100px;
    height: 100px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.xlarge {
  border: 2px solid $white;

  width: 120px;
  height: 120px;

  svg {
    width: 50px;
    height: 50px;
  }

  @media #{$desktop-large} {
    width: 150px;
    height: 150px;

    svg {
      width: 60px;
      height: 60px;
    }
  }
}

.hero {
  border-color: gold;
  box-shadow: 0 0 20px gold;
}

.wreath {
  pointer-events: none;
}

.wreathsmall {
  position: absolute;
  top: 62%;
  left: 33%;
  transform: translate(-50%, -50%) scale(0.5);

  @media #{$desktop-large} {
    top: 62%;
    left: 50%;
  }
}

.wreathmedium {
  position: absolute;
  top: 63%;
  left: 33%;
  transform: translate(-50%, -50%) scale(0.5);

  @media #{$desktop-large} {
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
  }
}

.wreathlarge {
  position: absolute;
  top: 59%;
  left: 43%;
  transform: translate(-50%, -50%) scale(1.3);

  @media #{$desktop-large} {
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.6);
  }
}

.wreathxlarge {
  position: absolute;
  top: 62%;
  left: 45%;
  transform: translate(-50%, -50%) scale(2);
  @media #{$desktop-large} {
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2.5);
  }
}
