@import '../../../styles/colors';
@import '../../../styles/variables';

.compWrap {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.filter {
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0.6;
  height: 100vh;
  background-color: $grey;
}

.content {
  margin-top: -40px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 2;
}

.transition {
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, transparent 0%, $grey 100%);
  bottom: 0;
}

.info {
  margin-top: -200px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  & > :nth-child(2) {
    margin-right: 12px;
  }

  a {
    color: $white;
  }
}

.heading {
  transform: scale(1);
  transition: transform 0.5s ease;
  line-height: 1.2;
  font-weight: lighter;
  font-size: 25vmin;
  @media #{$tablet} {
    font-size: 20vmin;
    line-height: 1;
  }
}

.subheading {
  font-size: 6vmin;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 12px;
  @media #{$tablet} {
    font-size: 4.3vmin;
    line-height: 1;
  }
}

.actions:hover ~ .heading {
  transform: scale(1.05);
  transition: transform 0.5s ease;
  span:nth-child(1) {
    color: $red;
  }
  span:nth-child(2) {
    color: $yellow;
  }
  span:nth-child(3) {
    color: $beige;
  }
  span:nth-child(4) {
    color: $red;
  }
  span:nth-child(5) {
    color: $yellow;
  }
  span:nth-child(6) {
    color: $beige;
  }
}
