@import '../../../styles/colors';
@import '../../../styles/variables';

.compWrap {
  background: $grey;
  padding: 2px 12px;
}

.text {
  margin: auto;
  text-align: center;
  color: $white;
  max-width: 860px;

  p {
    margin: 0;
    letter-spacing: 1px;
    font-size: 0.8em;

    @media #{$desktop-medium} {
      font-size: 1em;
    }
  }

  a {
    color: $yellow;
    font-weight: bold;
  }
}