@import '../../../styles/variables';

.compWrap {
  max-width: 1080px;
  margin: 100px auto 60px auto;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 50px;
  }
}

.members {
  margin: 120px 0 20px 0;
  display: grid;
  grid-template-columns: 1fr;

  @media #{$desktop-medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


.members2 {
  margin: 40px 0 120px 0;
  display: grid;
  grid-template-columns: 1fr;

  @media #{$desktop-medium} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}