$red: #dd5828;
$yellow: #f2d15e;
$green: #2ecd8e;
$beige: #f6e5bf;
$grey: #1a1819;
$light-grey: #b7b7b7;
$dark-grey: #888888;
$black-grey: #2f2f2f;
$supa-dark-grey: #232323;
$white: #ffffff;
