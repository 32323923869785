@import '../../../styles/colors';
@import '../../../styles/variables';

.mScroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 50px;
  margin: auto;
  overflow: hidden;
  z-index: 1;

  @media #{$tablet} {
    height: 100px;
  }
}

.title {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: all 1s ease;
  & > div {
    display: flex;
    animation: scrollText 33s infinite linear;
  }
  h2 {
    margin: 0;
    letter-spacing: 15px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.1);
    transition: all 2s ease;

    @media #{$tablet} {
      letter-spacing: 20px;
      font-size: 50px;
    }

    .name {
      margin: 0 10px 0 10px;

      &:before {
        content: '\2022';
        margin-right: 16px;
        vertical-align: -30%;
      }

      &:hover {
        span:nth-child(1) {
          color: $red;
        }
        span:nth-child(2) {
          color: $yellow;
        }
        span:nth-child(3) {
          color: $beige;
        }
        span:nth-child(4) {
          color: $red;
        }
        span:nth-child(5) {
          color: $yellow;
        }
        span:nth-child(6) {
          color: $beige;
        }
      }
    }
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
