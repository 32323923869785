@import '../../../styles/colors';
@import '../../../styles/variables';

.compWrap {
  position: relative;
}

.filter {
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0.6;
  height: 100%;
  background-color: $grey;
}

.transitionTop {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 30%;
  width: 100%;
  background: linear-gradient(to top, transparent 0%, $grey 100%);
}

.transitionBot {
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 30%;
  width: 100%;
  background: linear-gradient(to bottom, transparent 0%, $grey 100%);
}

.content {
  max-width: 992px;
  margin: auto;
  position: relative;
  z-index: 2;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  svg {
    width: 88px;
    height: 53px;
    margin-bottom: 8px;
  }

  h1 {
    letter-spacing: 10px;
  }
}

.image {
  z-index: 1;
}

.main {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10em;
}

.insta {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 84px 0;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instaLogo {
  border: solid 2px $white !important;
  border-radius: 50%;
}

.handle {
  margin-top: 12px;

  a {
    font-weight: bold;
    text-transform: lowercase;
    color: $white;
  }
}

.error {
  text-align: center;
  padding: 20px;
  margin: auto;
}

.media {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media #{$tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }

  @media #{$desktop-medium} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
}